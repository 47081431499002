import styled from "styled-components"

export const Container = styled.div`
  width: ${props =>
    props.name === "st" || props.name === "zip" ? "60%" : "100%"};
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & label {
    padding-bottom: 7.5px;
    cursor: pointer;

    & > span.error {
      color: #721c24;
    }
  }

  & select {
    width: 100%;
    height: 34px;
    background-color: #fff;
    border: 1px solid #cecece;
    border-radius: 4px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  & input[type="text"].error-border,
  & input[type="password"].error-border {
    border-color: #721c24;
  }

  & input[type="text"]:focus,
  & input[type="password"]:focus {
    border-color: #2d77b2;
  }
`
