import React, { useState, useEffect, Fragment } from "react"
import { navigate } from "gatsby"
import { API_URL, getOrder } from "../../../utils/helpers"
import Loading from "../../Loading"
import Alert from "../../Alert"
import Searched from "./Searched"
import SearchedResults from "./SearchedResults"

const Results = () => {
  const [loading, setLoading] = useState(true)
  const [criteria, setCriteria] = useState({})
  const [items, setItems] = useState([])
  const [alert, setAlert] = useState({})

  useEffect(() => {
    if (getOrder() && getOrder().zip) {
      const urlParams = new URLSearchParams(window.location.search)

      if (
        urlParams.has("type") &&
        urlParams.has("tonnage") &&
        getOrder().type === urlParams.get("type")
      ) {
        init(urlParams.get("type"), urlParams.get("tonnage"))
      } else {
        navigate("/buy/1")
      }
    }
  }, [])

  const init = async (type, tonnage, sort = "price|desc") => {
    const url = new URL(`${API_URL}/buyResultsInit`)
    const params = { type, tonnage, sort }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setCriteria({
          install: `${getOrder().city}, ${getOrder().st} ${getOrder().zip}`,
          type: json.fmtType,
          tonnage: json.fmtTonnage,
          sort: json.sort,
        })
        setItems(json.items)
      } else if (json && json.resp === 2) {
        setCriteria({
          install: `${getOrder().city}, ${getOrder().st} ${getOrder().zip}`,
          type: json.fmtType,
          tonnage: json.fmtTonnage,
        })
        setAlert({
          type: "notice",
          text: json.text,
        })
      } else {
        setAlert({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
    setLoading(false)
  }

  const handleSort = sort => {
    setLoading(true)
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has("type") && urlParams.has("tonnage")) {
      init(urlParams.get("type"), urlParams.get("tonnage"), sort)
    }
  }

  return (
    <article>
      {loading ? (
        <Loading />
      ) : alert.type ? (
        <Fragment>
          <Searched data={criteria} num={items.length} sort={handleSort} />
          <Alert data={alert} />
        </Fragment>
      ) : (
        <Fragment>
          <Searched data={criteria} num={items.length} sort={handleSort} />
          <SearchedResults items={items} location={getOrder().location || ""} />
        </Fragment>
      )}
    </article>
  )
}

export default Results
