import React from "react"
import { Router } from "@reach/router"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Step1 from "../components/buy/Step1"
import Step2 from "../components/buy/Step2"
import Step3 from "../components/buy/Step3"
import Results from "../components/buy/Results"
import { Main, MainContainer } from "../components/buy/styled"

const Buy = () => {
  return (
    <Layout>
      <Helmet
        name="HVAC Match Questionaire"
        description="Answer a few simple questions and our “HVAC Match” technology will accurately return all the high efficiency AC systems that fit your needs."
        slug="buy/1"
      />
      <Header />
      <PageHdr title="Shop for a New AC Unit" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <Router style={{ width: "100%" }}>
              <Step1 path="/buy/1" />
              <Step2 path="/buy/2" />
              <Step3 path="/buy/3" />
              <Results path="/buy/results" />
            </Router>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Buy
