import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const Item = styled.div`
  border: 1px solid #ddd;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const FlexContainer = styled.div`
  & > div {
    position: relative;
    border-bottom: 1px solid #ddd;
  }

  & > div:last-child {
    border-bottom: none;
  }

  & > div.two-images {
    margin-top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  & > img {
    width: 40%;
  }
`

export const Badge = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 13%;
`

export const Image = styled.div`
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &:first-child {
    border-right: none;
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  & > img.smaller {
    width: 75%;
  }

  & > small {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.8rem;
    font-style: italic;
    font-family: arial;
  }
`

export const Details = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;

  & > ul {
    margin: 0;
    padding: 0;
    font-size: 0.85rem;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 35px;

    & > li {
      padding-bottom: 5px;
      padding-right: 15px;
      line-height: 16px;
    }
  }
`

export const Cost = styled.div`
  margin: 15px;

  & > div {
    padding: 15px;
    background-color: #2e77b2;
    display: flex;
    flex-direction: column;

    & > small {
      color: #fff;
      line-height: 20px;
      font-size: 0.9rem;
      letter-spacing: 0.25px;
    }

    & > span {
      color: #fff;
      padding-top: 5px;
      font-size: 1.5rem;
      font-family: "Muli", sans-serif;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 1.25px;
    }
  }
`

export const Buttons = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:first-child {
    margin-bottom: 7.5px;
  }

  & > *:last-child {
    margin-top: 7.5px;
  }
`
