import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { API_URL, getOrder, setOrder } from "../../../utils/helpers"
import Input from "../../form/Input"
import Msg from "../../form/Msg"
import Submit from "../../form/Submit"
import { Grid, ButtonContainer } from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Step1 = () => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})

  useEffect(() => {
    if (getOrder() && getOrder().zip) {
      init(getOrder().zip)
    }
  }, [])

  const init = installZip => {
    setNames({
      installZip,
    })
  }

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/buyStep1`)
    let data = new FormData()
    data.append("installZip", names.installZip || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        let order = {
          city: json.city,
          st: json.st,
          zip: json.zip,
        }
        if (getOrder() && getOrder().contact) {
          order = getOrder()
          order.city = json.city
          order.st = json.st
          order.zip = json.zip
        }
        setOrder(order)
        navigate(json.next)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <article>
      <h2>Step 1, zip code where the unit will be installed.</h2>
      <form method="post" onSubmit={e => handleSubmit(e)}>
        <Grid>
          <p>Please enter your installation zip code.</p>
          <p>Step 1 of 3</p>
          <div className="fields">
            <div className="left">
              <div>
                <Input
                  type="text"
                  label="Zip Code"
                  req={true}
                  name="installZip"
                  value={names.installZip}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <div className="submit">
              {msg.type && <Msg data={msg} />}

              <ButtonContainer
                className={msg.type === "working" ? "hidden" : ""}
              >
                <Submit name="Next Step" icon={faChevronCircleRight} />
              </ButtonContainer>
            </div>
          </div>
          <div className="fields second">
            <div className="right">
              <img
                src="https://tjpc.com/img/franklin-products.png"
                alt="Franklin AC Units"
              />
              <ul>
                <li>
                  Mechanical AC is changing the way you can buy a new AC unit.
                  Use our matching system to find your new unit and know the
                  upfront cost{" "}
                  <strong>including professional installation</strong>.
                </li>
                <li>
                  We currently offer our concierge installation service of your
                  new AC unit at your location in the following counties: St.
                  Lucie, Martin and Palm Beach.
                </li>

                <li>
                  If you have any questions, please{" "}
                  <Link to="/contact">contact us</Link> or call (561) 848-6227
                  for assistance.
                </li>
              </ul>
            </div>
          </div>
        </Grid>
      </form>
    </article>
  )
}

export default Step1
