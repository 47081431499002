import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { API_URL, TONNAGES, getOrder, setOrder } from "../../../utils/helpers"
import Radios from "../../form/Radios"
import Msg from "../../form/Msg"
import Submit from "../../form/Submit"
import { Grid, ButtonContainer } from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Step3 = () => {
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/buyStep3`)
    let data = new FormData()
    data.append("tonnage", names.tonnage || "")
    data.append("order", JSON.stringify(getOrder()))

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        if (getOrder() && getOrder().zip) {
          let order = getOrder()
          order.tonnage = json.tonnage
          setOrder(order)
          navigate(json.next)
        } else {
          setMsg({
            type: "error",
            text: "An error has occurred.",
          })
        }
      } else {
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <article>
      <h2>Step 3, system tonnage.</h2>
      <form method="post" onSubmit={e => handleSubmit(e)}>
        <Grid>
          <p>Please select your tonnage.</p>
          <p>Step 3 of 3</p>
          <div className="fields">
            <div className="left">
              <div className="one">
                <Radios
                  name="tonnage"
                  hdg="Choose your tonnage size"
                  update={handleUpdate}
                  data={TONNAGES.map(item => {
                    return {
                      value: item.id,
                      label: `${item.id} tons (${item.sf})`,
                      checked: names.tonnage === item.id ? true : false,
                    }
                  })}
                />
              </div>
            </div>
          </div>
          <div className="fields second">
            <div className="right">
              <ul>
                <li>
                  Square footage is generally an accurate estimator for picking
                  the correct tonnage size.
                </li>

                <li>
                  Every installation has a job site inspection, Mechanical AC
                  will be able to verify that you ordered the correct unit at
                  that time.
                </li>

                <li>
                  If you have any questions, please{" "}
                  <Link to="/contact">contact us</Link> or call (561) 848-6227
                  for assistance.
                </li>
              </ul>
            </div>
          </div>

          <div className="submit">
            {msg.type && <Msg data={msg} />}

            <ButtonContainer className={msg.type === "working" ? "hidden" : ""}>
              <Submit
                name="Display Matching Systems"
                icon={faChevronCircleRight}
              />
            </ButtonContainer>
          </div>
        </Grid>
      </form>
    </article>
  )
}

export default Step3
