import styled from "styled-components"
import {
  Table as BaseTable,
  Select as BaseSelect,
} from "../../../../elements/Layout/styled"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.25fr 0.5fr;
  grid-gap: 25px;
  margin-bottom: 25px;
  align-items: center;

  &.extra-padding {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1023px) {
    align-items: end;
    grid-template-columns: 0.5fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  & > div {
    text-align: center;

    & > h3 {
      font-size: 1.25rem;
      font-family: Muli, sans-serif;
      line-height: 20px;
    }
  }

  & > div.hidden {
    display: none;
  }

  & > div.table {
    @media screen and (max-width: 1023px) {
      grid-column: 2;
      grid-row: 1 / 3;
    }
    @media screen and (max-width: 768px) {
      grid-column: 1;
      grid-row: 1 / 1;
    }
  }
`

export const Table = styled(BaseTable)`
  & th,
  & td {
    font-size: 0.85rem;
    padding: 5px 7.5px;
  }

  & th {
    text-align: right;
  }

  & td {
    text-align: left;
  }
`

export const Sort = styled.div`
  display: flex;
  align-items: center;

  & > span {
    padding-right: 7.5px;
  }
`

export const Select = styled(BaseSelect)`
  width: 100%;
`
