import React, { Fragment } from "react"
import { navigate } from "gatsby"
import {
  getCart,
  setCart,
  fmtCurrency,
  fmtSeer,
  fmtTonnage,
  fmtBtu,
  getLocationPrice,
} from "../../../../utils/helpers"
import ButtonLink from "../../../ButtonLink"
import Button from "../../../Button"
import {
  Container,
  Item,
  FlexContainer,
  Logo,
  Badge,
  Image,
  Details,
  Cost,
  Buttons,
} from "./styled"
import { faInfoCircle, faCartPlus } from "@fortawesome/pro-light-svg-icons"

const SearchedResults = ({ items, location }) => {
  const handleATC = (e, itemData) => {
    e.preventDefault()
    let cart = getCart()
    let newItem = {
      product: "system",
      systemId: itemData.systemId,
      qty: 1,
      item: `Franklin ${itemData.fmtType} ${itemData.ahri}`,
      subitem: `${fmtSeer(itemData.seer)} / ${fmtTonnage(
        itemData.tonnage
      )} / ${fmtBtu(itemData.btu)}`,
      price: itemData.price,
      extras: [
        {
          type: "thermostat",
          name: "Honeywell T6 Thermostat",
          price: 0,
        },
        {
          type: "other",
          name: location ? `${location} Air Handler Install` : "Unit Install",
          price: getLocationPrice(location),
        },
      ],
    }
    /*
    {
      type: "promo",
      name: "First Year Maintenance Plan",
      price: 0,
    },
    */
    cart.push(newItem)
    setCart(cart)
    navigate("/cart")
  }

  return (
    <Container>
      {items.map(item => {
        return (
          <Item key={item.systemId}>
            <FlexContainer>
              <div>
                <Logo>
                  <img
                    src={item.manufacturer.logo}
                    alt={item.manufacturer.name}
                  />
                </Logo>
                <Badge
                  src={item.warranty || "https://placeimg.com/1/1/any"}
                  alt="Warranty"
                  className={item.warranty ? "" : "hidden"}
                />
              </div>

              <Fragment>
                {item.type === "acPackage" || item.type === "hpPackage" ? (
                  <div>
                    <Image>
                      <img
                        src={item.condenser.img}
                        alt={item.condenser.model}
                        className="smaller"
                      />
                      <small>{item.condenser.model}</small>
                    </Image>
                  </div>
                ) : (
                  <div className="two-images">
                    <Image>
                      <img src={item.handler.img} alt={item.handler.model} />
                      <small>{item.handler.model}</small>
                    </Image>

                    <Image>
                      <img
                        src={item.condenser.img}
                        alt={item.condenser.model}
                        className="smaller"
                      />
                      <small>{item.condenser.model}</small>
                    </Image>
                  </div>
                )}
              </Fragment>

              <div>
                <Details>
                  <ul>
                    {item.details.map((detail, index) => {
                      return <li key={index}>{detail}</li>
                    })}
                  </ul>
                </Details>
              </div>
            </FlexContainer>

            <FlexContainer>
              <div>
                <Cost>
                  <div>
                    <small>System cost including installation:</small>
                    <span>{fmtCurrency(item.price)}</span>
                  </div>
                </Cost>

                <Buttons>
                  <ButtonLink
                    to={item.slug}
                    name="More Details"
                    icon={faInfoCircle}
                  />
                  <Button
                    item={item}
                    click={handleATC}
                    name="Add to Cart"
                    icon={faCartPlus}
                  />
                </Buttons>
              </div>
            </FlexContainer>
          </Item>
        )
      })}
    </Container>
  )
}

export default SearchedResults
