import React, { useState } from "react"
import { navigate } from "gatsby"
import { API_URL, getOrder, setOrder } from "../../../utils/helpers"
import image1 from "../../../img/franklin-split.png"
import image2 from "../../../img/franklin-package.png"
import Radios from "../../form/Radios"
import Select from "../../form/Select"
import Msg from "../../form/Msg"
import Submit from "../../form/Submit"
import { Grid, Img, ButtonContainer } from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Step2 = () => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/buyStep2`)
    let data = new FormData()
    data.append("type", names.type || "")
    data.append("location", names.location || "")
    data.append("acOrHp", names.acOrHp || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        if (getOrder() && getOrder().zip) {
          let order = getOrder()
          order.type = json.type
          order.location = json.location
          setOrder(order)
          navigate(json.next)
        } else {
          setErrors([])
          setMsg({
            type: "error",
            text: "An error has occurred.",
          })
        }
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <article>
      <h2>Step 2, type of system and unit.</h2>
      <form method="post" onSubmit={e => handleSubmit(e)}>
        <Grid>
          <p>Please select your system type.</p>
          <p>Step 2 of 3</p>
          <div className="fields">
            <div className="left">
              <div className="one">
                <Radios
                  name="type"
                  hdg="Choose your system type"
                  update={handleUpdate}
                  data={[
                    {
                      value: "split",
                      label: "Split System",
                    },
                    {
                      value: "package",
                      label: "Package System",
                    },
                  ].map(item => {
                    return {
                      value: item.value,
                      label: item.label,
                      checked: names.type === item.value ? true : false,
                    }
                  })}
                />
              </div>

              <div
                className={
                  names.type === "split"
                    ? "top-margin one"
                    : "top-margin one hidden"
                }
              >
                <p>
                  In a Split System, the air handler is usually located in the
                  attic (almost always horizontal) or a closet/garage (usually
                  vertical).
                </p>
              </div>

              <div
                className={names.type === "split" ? "one" : "one hidden"}
                style={{ paddingBottom: "15px" }}
              >
                <Select
                  label="Please select the direction your air handler will be installed"
                  req="true"
                  name="location"
                  value=""
                  data={[
                    { value: "", name: "--" },
                    { value: "Horizontal", name: "Horizontal" },
                    { value: "Vertical", name: "Vertical" },
                  ]}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div
                className={
                  names.type === "package"
                    ? "top-margin one"
                    : "top-margin one hidden"
                }
              >
                <p>
                  In a Package System, units sit on the ground or rooftop
                  outside of the structure or building.
                </p>
              </div>

              <div className="top-margin2 one">
                <Radios
                  name="acOrHp"
                  hdg="Choose your unit type"
                  update={handleUpdate}
                  data={[
                    {
                      value: "ac",
                      label: "Straight AC",
                    },
                    {
                      value: "hp",
                      label: "Electric Heat Pump",
                    },
                  ].map(item => {
                    return {
                      value: item.value,
                      label: item.label,
                      checked: names.acOrHp === item.value ? true : false,
                    }
                  })}
                />
              </div>
            </div>
          </div>
          <div className="fields second">
            <div className="right">
              <Img>
                <img src={image1} alt="Franklin Split System" />
                <span>
                  A <strong>Split System</strong> is made up of two units: an
                  outside unit (condenser) and an inside unit (air handler).
                </span>
              </Img>

              <Img className="no-border">
                <img src={image2} alt="Franklin Package System" />
                <span>
                  A <strong>Package System</strong> contains both the condenser
                  and air handler in one.
                </span>
              </Img>

              <p>
                <strong>Straight AC</strong> provides basic air conditioning,
                the addition of heat strips give the system the ability to
                provide some heat.
              </p>

              <p>
                An <strong>Electric Heat Pump</strong> uses refrigerant to both
                cool and heat.
              </p>
            </div>
          </div>

          <div className="submit">
            {msg.type && <Msg data={msg} />}

            <ButtonContainer className={msg.type === "working" ? "hidden" : ""}>
              <Submit name="Next Step" icon={faChevronCircleRight} />
            </ButtonContainer>
          </div>
        </Grid>
      </form>
    </article>
  )
}

export default Step2
