import React from "react"
import ButtonLink from "../../../ButtonLink"
import { Container, Table, Sort, Select } from "./styled"
import { faChevronCircleLeft } from "@fortawesome/pro-light-svg-icons"

const Searched = ({ data, num, sort }) => {
  return (
    <Container className={num === 0 ? "extra-padding" : ""}>
      <div>
        <h3>{`${num} Result(s)`}</h3>
        <ButtonLink
          to={`/buy/1`}
          name="Start Over"
          icon={faChevronCircleLeft}
        />
      </div>

      <div className="table">
        <Table>
          <tbody>
            <tr>
              <th>Install Location:</th>
              <td>{data.install}</td>
            </tr>
            <tr>
              <th>Type:</th>
              <td>{data.type}</td>
            </tr>
            <tr>
              <th>Tonnage:</th>
              <td>{data.tonnage}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className={num === 0 ? "hidden" : ""}>
        <Sort>
          <span>Sort:</span>
          <Select onChange={e => sort(e.target.value)} defaultValue={data.sort}>
            <option value="price|asc">Price low to high</option>
            <option value="price|desc">Price high to low</option>
            <option value="seer|asc">SEER low to high</option>
            <option value="seer|desc">SEER high to low</option>
          </Select>
        </Sort>
      </div>
    </Container>
  )
}

export default Searched
